import React, { useState, useContext } from 'react'
import { Layout, Modal, Button, Form, Input, Typography, Row, Col } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import AuthContext from './../context/index'
import './Login.css'

const { Title } = Typography

function Login() {
  const [email, setEmail] = useState('james@thai.run')
  const [password, setPassword] = useState('123456')
  const { signIn } = useContext(AuthContext)
  const history = useHistory()

  const handleLogin = async () => {
    try {
      const userInfo = await signIn({ email, password})
      if (userInfo) {
        history.replace('/event')
        return
      }
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      if (errorCode === "auth/user-not-found") {
        Modal.error({ title: 'อีเมลไม่ถูกต้อง'})
      } else if (errorCode === "auth/wrong-password") {
        Modal.error({ title: 'รหัสผ่านไม่ถูกต้อง'})
      } else {
        Modal.error({ title: errorMessage })
      }
    }
  }
  
  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Layout.Content className="wrap-content" style={{ width: 400, margin: 'auto'}}>
        <Row justify="space-around" align="middle">
          <Col span={20}>
            <Title>เข้าสู่ระบบ</Title>
            <Form>
              <Form.Item>
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Button 
                  type="primary" 
                  onClick={handleLogin}
                  size="large"
                  block
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  ) 
}

export default Login