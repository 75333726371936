import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams, useHistory, Link } from "react-router-dom";
import { Layout, Table, Button, Space, Breadcrumb } from "antd";
import moment from "moment";
import exportToExcel from '../utils/exportToExcel'

const GET_RUNNER = gql`
  query getRunnerByEvent($eventId: MongoID!) {
    runnerMany(filter: { eventId: $eventId }) {
      eventId
      name
      bib
      slug
      birthDate
      race
      _id
      RFID
      team
      checkpoint {
        _id
        time
        position
      }
    }
    eventById(_id: $eventId) {
      _id
      name
    }
  }
`;

function ListRunner() {
  const history = useHistory();
  const { organizId, eventId } = useParams();
  const [isLoading, SetIsLoading] = useState(false)
  const { data, loading, refetch } = useQuery(GET_RUNNER, {
    variables: { eventId },
    fetchPolicy: "cache-and-network",
  });

  if (loading && !isLoading) return <div>loading runners...</div>;

  const { runnerMany: runners, eventById: event } = data;
  // console.log("runners", runners);
  const columns = [
    {
      title: "เลขบิบ",
      dataIndex: "bib",
      key: "bib",
      sorter: (a, b) => a.bib - b.bib,

    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return <Link to={`/runner/${organizId}/${eventId}/${record.bib}`}><span>{name}</span></Link>
      }
    },
    {
      title: "วันเกิด",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (birthDate) => {
        return moment(birthDate).isValid()
          ? moment(birthDate).format("DD/MM/YYYY")
          : "-";
      },
    },
    {
      title: "race",
      dataIndex: "race",
      key: "race",
    },
    {
      title: "team",
      dataIndex: "team",
      key: "team",
    },
  ];

  const exportData = async() => {
    await refetch()
    SetIsLoading(true)
    exportToExcel(runners)
    SetIsLoading(false)
  }

  return (
    <Layout>
      <Layout.Content>
        <Space style={{ width: "100%" }} direction="vertical">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/event/${organizId}`}>การแข่งขันทั้งหมด</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>รายชื่อนักวิ่ง ({event.name})</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ display: 'flex', gap: '10px', width: '100%'}}>
            <Button
              onClick={() =>
                history.push(`/runner-import/${organizId}/${eventId}`)
              }
            >
              เพิ่มนักวิ่ง
            </Button>
            <Button
              onClick={() =>
                exportData()
              }
              loading={isLoading}
            >
              export checkpoint
            </Button>
          </div>

          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={runners}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default ListRunner;
