import React from "react";
import { Card, Row, Col, Typography } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
// import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _find from 'lodash/find'


// const { Search } = Input

const GET_POSITION_COUNT = gql`
query($eventId: MongoID!) {
	eventById(_id: $eventId) {
		_id
		name
		slug
		organizId
		startTime
		endTime
		haveChipTime
		checkpoints {
			distance
			position
			cutOffTime
			name
		}
	}
  positionCount(eventId: $eventId){
    count
    slug
    position
  }
}
`


const Dashboard = () => {
  // const [searchKey, setSearchKey] = useState('')
  const { eventId, organizId } = useParams()
	// const slug = 'sp100'
	const {data, loading} = useQuery(GET_POSITION_COUNT, 
		{ 
			variables: { eventId },
			pollInterval: 12000
		} 
	)
  if(loading){
    return <p>loading...</p>
  }
	const { eventById: event } = data
	// console.log(event);
	// const sortData = _sortBy(data.positionCount, function(o) {return o.position})
	let filterData = []
	event.checkpoints.forEach(element => {
		const count = _find(data.positionCount, function(o){ return o.position === parseInt(element.position)})
		// console.log(count ,'cout');
		const res = {
			...element,
			count: count? count.count: 0
		}
		console.log(res);
		filterData.push(res)
	});
  // console.log(sortData,'ss');
  const { Title } = Typography;
	return <div style={{display: 'flex', flexDirection: 'column'}}>
			
				<Row gutter={[16,16]} style={{ width: '100%', }} direction="vertical">
					{filterData.map((e) => 
          <Col md={6} xs={24}>
						<Card style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px', borderRadius: '8px' }} key={e.position} title={<Title><Link to={`/position/${organizId}/${eventId}/${e.position}`}><p style={{textAlign: 'center', fontSize: '20px', paddingTop : '30px'}}>{e.name}</p></Link></Title>} >
							<p style={{textAlign: 'center', fontSize: '35px'}}>{_get(e, 'count', 0)}</p>
						</Card>
            
          </Col>
          
					)}
       </Row>
				
	</div>
}
export default Dashboard