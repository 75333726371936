import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Table, Layout, Typography, Button } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";

const GET_RUNNER_CLAIM_REWARD = gql`
  query runnerClaimReward($eventId: MongoID!) {
    runnerMany(filter: { eventId: $eventId }) {
      name
      slug
      bib
      RFID
      _id
      claim {
        rewardId
        reward {
          name
          eventId
        }
        runnerId
      }
    }
  }
`;

export default function ClaimReward() {
  const { organizId, eventId } = useParams();
  const { data, loading } = useQuery(GET_RUNNER_CLAIM_REWARD, {
    variables: { eventId },
    fetchPolicy: "cache-and-network",
  });
  const history = useHistory();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "RFID",
      dataIndex: "RFID",
      key: "RFID",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "bib",
      dataIndex: "bib",
      key: "bib",
    },
    {
      title: "Claim",
      dataIndex: "claim",
      key: "claim",
      render: (claim) => {
        return !claim ? <CloseCircleOutlined /> : <CheckCircleOutlined />;
      },
    },
  ];
  if (loading) return <div>loading runner...</div>;
  return (
    <Layout>
      <Layout.Content>
        <Typography.Title className="fontSukhumvit">
          ClaimReward
        </Typography.Title>
        <div style={{ paddingBottom: "20px" }}>
          <Button
            style={{
              border: "1px solid rgb(235, 235, 235)",
              boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px",
            }}
            onClick={() => history.push(`/reward/${organizId}/${eventId}`)}
          >
            รับรางวัล
          </Button>
        </div>
        <div>
          <Table
            rowKey={(recode) => recode._id}
            columns={columns}
            dataSource={data?.runnerMany}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
}
