import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, Card, Row, Col, Typography, Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import racepackClient from "../apollo-client/racepack";
import moment from "moment";
import _find from "lodash/find";

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!) {
    eventById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        name
        distance
        position
        cutOffTime
      }
    }
    checkpointLastByEventId(eventId: $eventId) {
      bib
      position
      time
      slug
      userId
      _id
      runner {
        name
        _id
      }
    }
  }
`;

const GET_PARTICIPANT_RACEPACK = gql`
  query ($eventId: String, $bib: String) {
    activities(
      filter: { status: "faceChecking", eventId: $eventId, bib: $bib }
    ) {
      _id
      photo
    }
  }
`;

function LastCheckpoint() {
  const { eventId } = useParams();
  const [bib, setBib] = useState("0");
  const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, {
    variables: { eventId },
    pollInterval: 10000,
  });
  const participantImage = useQuery(GET_PARTICIPANT_RACEPACK, {
    client: racepackClient,
    variables: { eventId: "65b72e313e7d580012be3573", bib },
  });

  useEffect(() => {
    if (!loading) {
      setBib(`${data.checkpointLastByEventId[0].bib}`);
    }
  }, [loading, data]);
  if (loading || participantImage.loading) return <div>loading...</div>;
  const { eventById: event, checkpointLastByEventId: checkpoints } = data;

  // console.log(checkpoints,'chec');
  const checkpointsInfo = event.checkpoints.map((cp) => cp);
  // checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift();
  }
  // console.log(checkpointsInfo);
  // console.log(checkpointsInfo,'checkpointsInfo');

  const obj =
    participantImage.data?.activities[
      participantImage.data.activities.length - 1
    ];

  // const cp = checkpoints.filter((e)=>e.bib === parseInt(bib))
  // const groupedCheckpoints = _groupBy(cp, cp => cp.bib)
  // let dataSource = buildData(event, checkpoints)
  // dataSource = sortRank(dataSource)
  // // console.log(checkpointsInfo,'checkpointsInfo');
  console.log(checkpoints, "dataSource");

  const getCheckpointName = (position) => {
    const res = _find(event.checkpoints, function (o) {
      return o.position === parseInt(position);
    });
    return res.name;
  };
  // const unionPosition = _unionBy(cp, checkpointsInfo,'position')
  // console.log(unionPosition,'unionPosition');
  const { Title } = Typography;

  // console.log(dataSource,'dataSource');
  return (
    <Layout>
      <Layout.Content>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Card
            style={{
              border: "1px solid rgb(235, 235, 235)",
              boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px",
              borderRadius: "8px",
              fontFamily: "Sukhumvit Set",
            }}
            title={
              <div style={{ display: "flex" }}>
                {obj && obj.photo ? (
                  <Avatar size={240} src={obj.photo} />
                ) : (
                  <Avatar size={240} icon={<UserOutlined />} />
                )}
                <Title
                  style={{ paddingLeft: "40px", paddingTop: "60px" }}
                  level={2}
                >
                  <p>เลขบิบ : {checkpoints[0]?.bib}</p>{" "}
                  <p>ชื่อ : {checkpoints[0]?.runner.name}</p>{" "}
                </Title>
              </div>
            }
          >
            <div>
              <Row
                gutter={[16, 16]}
                style={{ width: "100%" }}
                direction="vertical"
              >
                <Col span={8} style={{ textAlign: "" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "20px",
                      fontFamily: "Sukhumvit Set",
                    }}
                  >
                    {" "}
                    CheckPoints : {getCheckpointName(
                      checkpoints[0]?.position
                    )}{" "}
                  </p>
                </Col>
                <Col span={8}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontFamily: "Sukhumvit Set",
                    }}
                  >
                    {" "}
                    {checkpoints[0]?.time &&
                      moment(checkpoints[0]?.time).format("HH:mm:ss")}{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
          {checkpoints.map((item, index) => {
            if (index > 0) {
              return (
                <Card>
                  <Row
                    gutter={[16, 16]}
                    style={{ width: "100%" }}
                    direction="vertical"
                  >
                    <Col span={8}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontFamily: "Sukhumvit Set",
                        }}
                      >
                        {" "}
                        {item?.runner.name}{" "}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontFamily: "Sukhumvit Set",
                        }}
                      >
                        {" "}
                        CheckPoints : {getCheckpointName(item?.position)}{" "}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          fontFamily: "Sukhumvit Set",
                        }}
                      >
                        {" "}
                        {item?.time &&
                          moment(item?.time).format("HH:mm:ss")}{" "}
                      </p>
                    </Col>
                  </Row>
                </Card>
              );
            }
            return "";
          })}
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default LastCheckpoint;
