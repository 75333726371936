import React, { useState } from "react";
import {
  Layout,
  Form,
  Upload,
  Button,
  Table,
  message,
  Space,
  Breadcrumb,
} from "antd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams, useHistory, Link } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import papa from "papaparse";
import _get from 'lodash/get'

const MUTATION_IMPORT_RUNNERS = gql`
  mutation importRunners($records: [CreateManyrunnerInput!]!) {
    runnerCreateMany(records: $records) {
      createCount
    }
  }
`;

const GET_SLUT = gql`
  query getSlug($eventId: MongoID!) {
    eventById(_id: $eventId) {
      _id
      slug
      name
    }
  }
`;

function AddRunner() {
  const history = useHistory();
  const { organizId, eventId } = useParams();
  const [importRunner] = useMutation(MUTATION_IMPORT_RUNNERS);
  const { data, loading } = useQuery(GET_SLUT, { variables: { eventId } });
  const [runners, setRunners] = useState([]);

  if (loading) return <div>loading data...</div>;
  const { eventById: event } = data;

  const handleBeforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const { result } = e.target;
      let { data } = papa.parse(result, { header: true });
      const prepareData = data
        .filter((runner) => runner.bib)
        .map((runner) => ({
          bib: parseInt(runner.bib, 10),
          name: `${runner.first_name} ${runner.last_name}`,
          // name: `${runner.first_name} ${runner.last_name}`,
          birthDate: moment(runner.birthDate, "DD-MM-YYYY").isValid()
            ? moment(runner.birthDate, "DD-MM-YYYY")
            : null,
          race: runner.race,
          eventId: eventId,
          slug: event.slug,
          RFID: runner.RFID,
          team: runner.team,
          gender: _get(runner, 'gender', 'male').toLowerCase(),
        }));
      // console.log(prepareData);
      setRunners(prepareData);
    };
    reader.readAsText(file);
    return false;
  };

  const handleImport = async () => {
    console.log("runners", runners);
    const {
      data: {
        runnerCreateMany: { createCount },
      },
    } = await importRunner({ variables: { records: runners } });
    message.success(`เพิ่มนักวิ่ง ${createCount} คนสำเร็จ`);
    history.push(`/runner/${organizId}/${eventId}`);
  };

  const columns = [
    {
      title: "เลขบิบ",
      dataIndex: "bib",
      key: "bib",
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "วันเกิด",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (birthDate) =>
        birthDate !== null ? birthDate.format("DD/MM/YYYY") : "-",
    },
    {
      title: "race",
      dataIndex: "race",
      key: "race",
    },
    {
      title: "team",
      dataIndex: "team",
      key: "team",
    },
  ];

  return (
    <Layout>
      <Layout.Content>
        <Space style={{ width: "100%" }} direction="vertical">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/event/${organizId}`}>การแข่งขันทั้งหมด</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/runner/${organizId}/${eventId}`}>
                รายชื่อนักวิ่ง ({event.name})
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>เพิ่มข้อมูลนักวิ่ง</Breadcrumb.Item>
          </Breadcrumb>
          {/* <Typography.Title>เพิ่มข้อมูลนักวิ่ง</Typography.Title> */}
          <Form>
            <Form.Item>
              <Upload
                showUploadList={false}
                beforeUpload={(file) => handleBeforeUpload(file)}
              >
                <Button icon={<UploadOutlined />}>นำเข้าด้วยไฟล์ CSV</Button>
              </Upload>
            </Form.Item>
          </Form>
          <Table
            rowKey={(record) => record.bib}
            columns={columns}
            dataSource={runners}
          />
          <Button onClick={() => handleImport()}>นำเข้า</Button>
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default AddRunner;
