import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Layout, Table, Breadcrumb, Space, Button } from 'antd'
import { gql, useQuery } from '@apollo/client'
import _groupBy from 'lodash/groupBy'
import moment from 'moment-timezone'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _last from 'lodash/last'
import _orderBy from 'lodash/orderBy'
import _includes from 'lodash/includes'
// import _findIndex from 'lodash/findIndex'
// import last from 'lodash/last'
import ReactExport from "react-export-excel";
moment.tz.setDefault('Asia/Bangkok')

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!){
    eventById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        distance
        position
        cutOffTime
      }
    }
    checkpointByEventId(eventId: $eventId) {
      bib
      position
      time
      slug
      userId
      _id
    	runner {
        name
        _id
        team
        race
      }
    }
  }
`

function calcTime(before, current){
  const curTime = moment(current)
  const beforeTime = moment(before)
  console.log(moment(before).format('HH:mm:ss'))
  console.log(moment(current).format('HH:mm:ss'))
  const diff = curTime.diff(beforeTime)
  const duration = moment.duration(diff)
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

function calcPace(time, distance) {
  console.log({time, distance})
  const minutes = (time.hour()*60) + time.minute() + (time.second() / 60)
  const paceResult = minutes / distance
  const paceTime = moment.utc().startOf('day').add({ minutes: paceResult }).format('mm:ss')
  return paceTime
}

function buildData({ startTime, haveChipTime, checkpoints }, checkpointsData) {
  const results = []
  // const times = []
  for (const [key, value] of Object.entries(checkpointsData)) {
    if (key !== 'null') {
      const result = {
        bib: key
      }
      const checkpointsInfo = checkpoints.map(cp => cp)
      // console.log(checkpointsInfo);
      _reverse(value)
      const data = _unionBy(value, ({ position }) => position)
      const sortedData = _sortBy(data, e => e.position)
      let timeArr = []
      sortedData.forEach(data => {
        // const splitTime = calcTime(defaultTime, data.time)
        result[data.position] = moment(data.time).format('HH:mm:ss')
        result.name = _get(data, 'runner.name', '-')
        result.race = _get(data, 'runner.race', '-')
      })
      timeArr = sortedData.sort(function (left, right) {
        return moment.utc(left.time).diff(moment.utc(right.time))
      });

      // console.log(timeArr, 'timeArr');
     
      checkpointsInfo.forEach(({ position, distance }, index) => {  // รวมเวลาทั้งหมดไว้ใน array
        if(!result[position] || moment(result[position], 'HH:mm:ss').isAfter(moment(result[position+1], 'HH:mm:ss')) ) {
          result.time = 'DNF'
        }
        if (!result[position]) {
          result.gunTime = 'DNF'
          result.chipTime = 'DNF'
          result.avgPace = '-'
          return
        }
        if(position !== 0){
          // console.log(position, 'position')
          // console.log(typeof position)
          // const timeIndex = _findIndex(timeArr, function(t) { return t.position === position })
          // console.log(timeIndex, 'timeIndex');
          // const start = moment(result[position - 1], 'HH:mm:ss')
          let start = ''
          if(position === 1){
            start = moment(startTime)
          } else {
            start = moment(_get(timeArr[position - 2], 'time'))
          }

          // console.log(moment(start).format('HH:mm:ss'), 's')
          
          // console.log(start, 'start');
          const end = moment(result[position], 'HH:mm:ss')
          const diff = moment.utc(moment.duration(end.diff(start)).asMilliseconds())
          result[`pace${position}`] = diff.isValid() ? diff.format('mm:ss') : '-'
          // console.log(start.format('HH:mm:ss'), end.format('HH:mm:ss'), diff.format('mm:ss'), `pace${position}`);
        }
        
      })
      if (result.gunTime !== 'DNF') {  // คำนวนเวลาทั้งหมด
        // console.log(result);
        const start = moment(result[1], 'HH:mm:ss')
        const eventStart = moment(startTime, 'HH:mm:ss')
        console.log(eventStart, 'eventStart');
        const { position, distance } = _last(checkpointsInfo)
        const lastData = sortedData.find(d => d.position === position)
        // const totalTime = times.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(times[0]))
        // const gunTime = moment.utc(totalTime.asMilliseconds()).format("HH:mm:ss")
        const chipTime = calcTime(eventStart, lastData.time)
        result.avgPace = calcPace(moment(chipTime, 'HH:mm:ss'), distance)
        result.gunTime = calcTime(start, lastData.time)
        result.chipTime = chipTime //calcTime(defaultTime, lastData.time)
        result.time = result.time === 'DNF' ? 'DNF': calcTime(start, lastData.time)
      } 
      // console.log(result);
      results.push(result)
    }
  }

  return results
}

function sortRank(data) {
  const dnfData = data.filter(({ gunTime }) => gunTime === 'DNF')
  const finishData = data.filter(({ gunTime }) => gunTime !== 'DNF')
  const orderedData = _orderBy(finishData, o => moment(o.gunTime, 'HH:mm:ss'), ['asc'])
  const orderedDataWithPos = orderedData.map((data, index) => ({...data, pos: index+1}))
  // console.log('orderedDataWithPos', orderedDataWithPos)
  return orderedDataWithPos.concat(dnfData)
}
const CalculatefinisherTime = (startTime, finisherTime) => {
  const startDate = moment(startTime, 'HH:mm:ss')
  const endDate = moment(finisherTime, 'HH:mm:ss')
  
  const duration = moment.duration(endDate.diff(startDate)).abs();
  console.log({startDate, endDate, duration})
  if(duration > 0){
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;
    const formattedDuration = hours.toString().padStart(2, '0') + ':' +
    minutes.toString().padStart(2, '0') + ':' +
    seconds.toString().padStart(2, '0');
    return formattedDuration
  } else {
    return ''
  }
};

function ListCheckpoint() {
  const { eventId, organizId } = useParams()
  const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, { variables: { eventId }})

  if (loading) return <div>loading...</div>
  const { eventById: event , checkpointByEventId: checkpoints } = data
  const checkpointsInfo = event.checkpoints.map(cp => cp)
  // checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift()
  }
  console.log(checkpointsInfo);
  
  const columnsCheckpoint = checkpointsInfo.map((cp, index) => ({
    title: `เช็คพอยท์ ${index+1} (${cp.distance})`,
    dataIndex: cp.position,
    key: cp.position,
    render: (time, record) => {
      // console.log(record);
      if (!time) {
        return '-'
      }
      const place = record[`pace${cp.position}`]
      return `${time} \n (${place})`
    }
  }))

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "pos",
      key: "_id",
      render: (pos) => pos || "-",
    },
    {
      title: "เลขบิบ",
      dataIndex: "bib",
      key: "bib",
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "race",
      dataIndex: "race",
      key: "race",
      filters: [
        {
          text: 'Buffet 24 hours',
          value: 'Buffet 24 hours',
        },
        {
          text: 'A la Carte Run',
          value: 'A la Carte Run',
        },
        {
          text: 'Marathon Run 42 K',
          value: 'Marathon Run 42 K',
        },
      ],
      onFilter: (value, record) => record.race.indexOf(value) === 0
    },

    ...columnsCheckpoint,
    {
      title: "Time",
      dataIndex: "chipTime",
      key: "chipTime",
      render: (chipTime, record) => {
        
        // console.log(record)
        if(_includes(record.race, 'A la Carte Run')) {
          console.log(record, 'AL')
          if(record.lastposition === 4){
            const time = CalculatefinisherTime(event.startTime, record[`${4}`])
            return <span>{time}</span>
          } else if(record.lastposition === 3 ) {
            const time = CalculatefinisherTime(event.startTime, record[`${3}`])
            return <span>{time}</span>
          } else if(record.lastposition === 2 ) {
            const time = CalculatefinisherTime(event.startTime, record[`${2}`])
            return <span>{time}</span>
          } else {
            return <span>DNF</span>
          }
        }
        if(_includes(record.race, 'Marathon')){
          const marathonStart = "2024-02-03T21:00:00.483Z"
          if(record[`${7}`] && record[`${11}`]){
            const time = CalculatefinisherTime(marathonStart, record[`${11}`])
            return <span>{time}</span>
          }
        }
        if(chipTime === 'DNF') return <span>DNF</span>
        return <span>{chipTime}</span>
      }
    },
    // {
    //   title: 'Gun Time',
    //   dataIndex: 'gunTime',
    //   key: 'gunTime'
    // },
    // {
    //   title: 'AVG Pace',
    //   dataIndex: 'avgPace',
    //   key: 'avgPace'
    // },
    // {
    //   title: 'Time',
    //   dataIndex: 'totalTime',
    //   key: 'time'
    // },
  ];

  function LastCheckPoint(record) {
    let lastcp = ''
    record.forEach((csp, i) => {
      checkpointsInfo.forEach((cp, j) => {
        if(csp[`${j + 1}`]){
          lastcp = csp[`${j + 1}`]
          record[`${i}`].lastcp = lastcp
          record[`${i}`].lastposition = j + 1
        }
      })
      
    })
    return record
  }

  function CalLastTime(record){
    record.forEach((cp, i) => {
      record[`${i}`].totalTime = calcTime(moment(event.startTime), moment(cp.lastcp, 'HH:mm:ss'))
    })

    // const data =  _orderBy(record, o => [o.lastposition, moment(o.totalTime, 'HH:mm:ss')], ['desc', 'asc'])

    return record
  }

  const groupedCheckpoints = _groupBy(checkpoints, (cp) => cp.bib);
  let dataSource = buildData(event, groupedCheckpoints);
  dataSource= LastCheckPoint(dataSource)
  dataSource= CalLastTime(dataSource)
  dataSource = sortRank(dataSource);

  return (
    <Layout>
      <Layout.Content>
        <Space style={{ width: "100%" }} direction="vertical">
          <h1>Start: {moment(event.startTime).format("HH:mm:ss")}</h1>
          <ExcelFile element={<Button>Export</Button>}>
            <ExcelSheet data={dataSource} name="runner">
              <ExcelColumn label="ลำดับ" value="pos" />
              <ExcelColumn label="เลขบิบ" value="bib" />
              <ExcelColumn label="ชื่อ-สกุล" value="name" />
              <ExcelColumn label="เช็คพอยท์ 1" value="1" />
              <ExcelColumn label="เช็คพอยท์ 2" value="2" />
              <ExcelColumn label="เช็คพอยท์ 3" value="3" />
              <ExcelColumn label="เช็คพอยท์ 4" value="4" />
              <ExcelColumn label="เช็คพอยท์ 5" value="5" />
              <ExcelColumn label="chipTime" value="chipTime" />
            </ExcelSheet>
          </ExcelFile>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/event/${organizId}`}>การแข่งขันทั้งหมด</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
          </Breadcrumb>
          <Table
            scroll={{ x: true }}
            rowKey={(record) => record.bib}
            columns={columns}
            dataSource={dataSource}
            pagination={{ pageSize: 100 }}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default ListCheckpoint;
