import React from 'react'
import { useParams } from 'react-router-dom'
import { Layout, Table, Space } from 'antd'
import { gql, useQuery } from '@apollo/client'
import _groupBy from 'lodash/groupBy'
import moment from 'moment'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _last from 'lodash/last'
import _orderBy from 'lodash/orderBy'
import _findIndex from 'lodash/findIndex'
// import last from 'lodash/last'

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!){
    eventById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        distance
        position
        cutOffTime
      }
    }
    checkpointByEventId(eventId: $eventId) {
      bib
      position
      time
      slug
      userId
      _id
    	runner {
        name
        _id
      }
    }
  }
`

function calcTime(before, current){
  const curTime = moment(current)
  const beforeTime = moment(before)
  const diff = curTime.diff(beforeTime)
  const duration = moment.duration(diff)
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

function calcPace(time, distance) {
  const minutes = (time.hour()*60) + time.minute() + (time.second() / 60)
  const paceResult = minutes / distance
  const paceTime = moment.utc().startOf('day').add({ minutes: paceResult }).format('mm:ss')
  return paceTime
}

function buildData({ startTime, haveChipTime, checkpoints }, checkpointsData) {
  const results = []
  // const times = []
  for (const [key, value] of Object.entries(checkpointsData)) {
    if (key !== 'null') {
      const result = {
        bib: key
      }
      const checkpointsInfo = checkpoints.map(cp => cp)
      // console.log(checkpointsInfo);
      _reverse(value)
      const data = _unionBy(value, ({ position }) => position)
      const sortedData = _sortBy(data, e => e.position)
      let timeArr = []
      sortedData.forEach(data => {
        // const splitTime = calcTime(defaultTime, data.time)
        result[data.position] = moment(data.time).format('HH:mm:ss')
        result.name = _get(data, 'runner.name', '-')
      })
      // console.log(sortedData);
      timeArr = sortedData.sort(function (left, right) {
        return moment.utc(left.time).diff(moment.utc(right.time))
      });
      // console.log(timeArr);
     
      checkpointsInfo.forEach(({ position, distance }, index) => {  // รวมเวลาทั้งหมดไว้ใน array
        if(!result[position] || moment(result[position], 'HH:mm:ss').isAfter(moment(result[position+1], 'HH:mm:ss')) ) {
          result.time = 'DNF'
        }
        if (!result[position]) {
          result.gunTime = 'DNF'
          result.chipTime = 'DNF'
          result.avgPace = '-'
          return
        }
        if(position !== 0){
          // console.log(_findIndex(timeArr, function(t) { return t.position === position }));
          const timeIndex = _findIndex(timeArr, function(t) { return t.position === position })
          // const start = moment(result[position - 1], 'HH:mm:ss')
          const start = moment(_get(timeArr[timeIndex - 1], 'time'), 'HH:mm:ss')
          // console.log(start);
          const end = moment(result[position], 'HH:mm:ss')
          const diff = moment.utc(moment.duration(end.diff(start)).asMilliseconds())
          result[`pace${position}`] = diff.isValid() ? diff.format('mm:ss') : '-'
        }
        
      })
      if (result.gunTime !== 'DNF') {  // คำนวนเวลาทั้งหมด
        // console.log(result);
        const start = moment(result[1], 'HH:mm:ss')
        // console.log(start);
        const { position, distance } = _last(checkpointsInfo)
        const lastData = sortedData.find(d => d.position === position)
        // const totalTime = times.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(times[0]))
        // const gunTime = moment.utc(totalTime.asMilliseconds()).format("HH:mm:ss")
        const chipTime = calcTime(start, lastData.time)
        result.avgPace = calcPace(moment(chipTime, 'HH:mm:ss'), distance)
        result.gunTime = calcTime(start, lastData.time)
        result.chipTime = chipTime //calcTime(defaultTime, lastData.time)
        result.time = result.time === 'DNF' ? 'DNF': calcTime(start, lastData.time)
      } 
      // console.log(result);
      results.push(result)
    }
  }

  return results
}

function sortRank(data) {
  const dnfData = data.filter(({ gunTime }) => gunTime === 'DNF')
  const finishData = data.filter(({ gunTime }) => gunTime !== 'DNF')
  const orderedData = _orderBy(finishData, o => moment(o.gunTime, 'HH:mm:ss'), ['asc'])
  const orderedDataWithPos = orderedData.map((data, index) => ({...data, pos: index+1}))
  // console.log('orderedDataWithPos', orderedDataWithPos)
  return orderedDataWithPos.concat(dnfData)
}

function PublicBoard() {
  const { eventId } = useParams()
  const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, { variables: { eventId }})

  if (loading) return <div>loading...</div>
  const { eventById: event , checkpointByEventId: checkpoints } = data
  const checkpointsInfo = event.checkpoints.map(cp => cp)
  // checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift()
  }
  // console.log(checkpointsInfo);
  
  const columnsCheckpoint = checkpointsInfo.map((cp, index) => ({
    title: `เช็คพอยท์ ${index+1} (${cp.distance})`,
    dataIndex: cp.position,
    key: cp.position,
    render: (time, record) => {
      // console.log(record);
      if (!time) {
        return '-'
      }
      const place = record[`pace${cp.position}`]
      return `${time} \n (${place})`
    }
  }))

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'pos',
      key: '_id',
      render: pos => pos || '-'
    },
    {
      title: 'เลขบิบ',
      dataIndex: 'bib',
      key: 'bib'
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'name',
      key: 'name'
    },
    ...columnsCheckpoint,
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time'
    },
  ]
  
  const groupedCheckpoints = _groupBy(checkpoints, cp => cp.bib)
  let dataSource = buildData(event, groupedCheckpoints)
  dataSource = sortRank(dataSource)
  return (
    <Layout>
      <Layout.Content>
        <Space style={{ width: '100%' }} direction="vertical">
          <h1>ผลการแข่งขันงาน {event.name}</h1>
          <Table rowKey={record => record.bib} columns={columns} dataSource={dataSource} pagination={{ pageSize: 100 }} />
        </Space>
      </Layout.Content>
    </Layout>
  )
}

export default PublicBoard