import React from 'react'
import { Layout, Space, Spin, Table } from "antd"
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import moment from 'moment'


const GET_CHECKPOINT = gql`
  query getCheckpoint($eventId: MongoID!, $bib: Int!){
    checkpointByRunner(eventId: $eventId, bib: $bib) {
      bib
      position
      time
      slug
      userId
      _id
    	runner {
        name
        _id
      }
    }
  }
`





const RunnerDetail = () => {
  const { eventId, bib } = useParams()
  const { data, loading } = useQuery(GET_CHECKPOINT, { variables: { eventId, bib: parseInt(bib) }})

  

  if(loading){
    return (
      <Spin>Loading</Spin>
    )
  }

  const columns = [
    {
      title: "เลขบิบ",
      dataIndex: "bib",
      key: "bib",
    },
    {
      title: "ชื่อ",
      dataIndex: "runner",
      key: "runner",
      render: (runner)=> runner.name
    },
    {
      title: "ตำแหน่ง",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "เวลา",
      dataIndex: "time",
      key: "time",
      render: (time)=> moment(time).format('DD/MM/YYYY HH:mm:ss')
    },
    
  ]

  const dataSource = data.checkpointByRunner

  return(
    <Layout>
      <Layout.Content>
        <Space style={{ width: "100%" }} direction="vertical">
          <Table 
            scroll={{ x: true }}
            rowKey={(record) => record.bib}
            columns={columns}
            dataSource={dataSource}
            pagination={{ pageSize: 100 }}
          />
        </Space>
      </Layout.Content>
    </Layout>
  )

}

export default RunnerDetail