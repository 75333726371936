import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";

const exportToExcel = (mydata) => {
  // Create an array to hold the rows of data for Excel
  const excelData = [];

  // Loop through each item in the data and format the data for Excel
  mydata.forEach((item) => {
    // If the item has checkpoints, loop through each checkpoint and create a row for it
    item.checkpoint.forEach((checkpoint) => {
      excelData.push({
        ID: item._id,
        Bib: item.bib,
        Name: item.name,
        Time: moment(checkpoint.time).format('DD/MM/YYYY HH:mm:ss'),
        Position: checkpoint.position,
      });
    });
  });

  // Create a worksheet
  const worksheet = XLSX.utils.json_to_sheet(excelData);

  // Create a workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

  // Write the Excel file to a binary string
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Save the Excel file
  const fileName = `checkpoint_data_${moment()}.xlsx`;
  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, fileName);
};

export default exportToExcel;
