import React, { useContext, useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import {
  Layout,
  Typography,
  Table,
  Space,
  Button,
  Modal,
  Form,
  Input,
  message,
  Card,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import AuthContext from "./../context";
import EditOrganiz from "./EditOrganiz";

const GET_ORGANIZ = gql`
  query getOrganiz {
    organizMany(filter: { deleted: false }) {
      _id
      name
      userId
    }
  }
`;
const CREATE_ORGANIZ = gql`
  mutation createOrganiz($record: CreateOneorganizInput!) {
    organizCreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

const REMOVE_ORGANIZ = gql`
  mutation removeOrganiz($organizId: MongoID!) {
    removeOrganiz(organizId: $organizId)
  }
`;

function useRouteQuery() {
  return new URLSearchParams(useLocation().search);
}

function ListOrganiz() {
  const [addVisible, setAddVisible] = useState(false);
  const [createOrganiz] = useMutation(CREATE_ORGANIZ);
  const [removeOrganiz] = useMutation(REMOVE_ORGANIZ);
  const { selectOrganizer, userId } = useContext(AuthContext);
  const { data: organizer, loading, refetch } = useQuery(GET_ORGANIZ);
  const history = useHistory();
  const [form] = Form.useForm();
  const query = useRouteQuery();
  const isAdmin = userId === "fnywEdRHF0SmOAUUrfJmmGV1nv73";

  const editId = query.get("editId");
  const isEdit = editId !== null;

  if (loading) return <div>loading events...</div>;
  const { organizMany: organizers } = organizer;
  let organizerfilter = organizers;
  if (!isAdmin) {
    organizerfilter = organizers.filter((organiz) => organiz.userId === userId);
  }

  const handleSelectOrganizer = (_id, record) => {
    history.push(`/event/${_id}`);
    selectOrganizer({ organizInfo: record });
  };

  const handleRemoveOrganizer = (organizId) => {
    Modal.confirm({
      title: "คุณต้องการจบผู้จัดใช่หรือไม่",
      content: "ข้อมูลผู้ใช้และการแข่งจะถูกลบทั้งหมด",
      onCancel() {
        return;
      },
      async onOk() {
        await removeOrganiz({ variables: { organizId } });
        message.success("ลบสำเร็จ");
        refetch();
      },
    });
  };

  const columns = [
    {
      title: "ชื่อผู้จัดงาน",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "จัดการ",
      dataIndex: "_id",
      key: "_id",
      render: (_id, record) => {
        return (
          <Space>
            <Button onClick={() => handleSelectOrganizer(_id, record)}>
              จัดการ
            </Button>
            <Button onClick={() => history.push(`/organizer?editId=${_id}`)}>
              แก้ไข
            </Button>
            {isAdmin && (
              <Button onClick={() => handleRemoveOrganizer(_id)}>ลบ</Button>
            )}
            {/* <Button onClick={() => handleRemoveOrganizer(_id)}>ลบ</Button> */}
          </Space>
        );
      },
    },
  ];

  return (
    <Layout>
      <Layout.Content style={{ width: 800, margin: "auto", padding: 20 }}>
        <Typography.Title className="fontSukhumvit">
          รายชื่อผู้จัดงาน
        </Typography.Title>
        <div style={{ paddingBottom: "20px" }}>
          {isAdmin && (
            <Button
              style={{
                border: "1px solid rgb(235, 235, 235)",
                boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px",
              }}
              onClick={() => setAddVisible(true)}
            >
              เพิ่มผู้จัด
            </Button>
          )}
        </div>
        <Card
          style={{
            border: "1px solid rgb(235, 235, 235)",
            boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px",
            borderRadius: "8px",
          }}
        >
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={organizerfilter}
          />
        </Card>
        <Modal
          title="เพิ่มผู้จัดงาน"
          visible={addVisible}
          onOk={() => form.submit()}
          onCancel={() => setAddVisible(false)}
        >
          <Form.Provider
            onFormFinish={async (formName, { values }) => {
              if (formName === "addOrganizer") {
                const { name } = values;
                const record = { name };
                try {
                  await createOrganiz({ variables: { record } });
                  Modal.success({
                    content: "สร้างผู้จัดสำเร็จ",
                    onOk: () => {
                      setAddVisible(false);
                      refetch();
                    },
                  });
                } catch (e) {
                  console.log("e", e);
                }
              }
            }}
          >
            <Form form={form} name="addOrganizer">
              <Form.Item
                label="ชื่อผู้จัดงาน"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกข้อมูล",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Form.Provider>
        </Modal>
        {isEdit && <EditOrganiz editId={editId} refetch={refetch} />}
      </Layout.Content>
    </Layout>
  );
}

export default ListOrganiz;
