import React from "react";
import { useParams, Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Table, Typography, Card } from 'antd'
import moment from 'moment'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!, $position: Int){
    eventById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        distance
        position
        cutOffTime
				name
      }
    }
    checkpointByposition(eventId: $eventId, position: $position) {
      bib
      position
      time
      slug
      userId
      _id
    	runner {
        name
        _id
        race
      }
    }
  }`

const PositionPage = () => {
	const { eventId, organizId, position } = useParams()
 console.log(eventId, organizId, position);

 const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, { variables: { eventId, position: parseInt(position) }})

 if (loading) return <div>loading...</div>

 const { eventById: event , checkpointByposition: checkpoints } = data
//  console.log(event);
 const checkpointsInfo = event.checkpoints.map(cp => cp)
  // checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift()
  }
  // console.log(checkpointsInfo,'checkpointsInfo');

	const cpDetail = _find(event.checkpoints, function(o){ return o.position === parseInt(position)})
  const unionPosition = _unionBy(checkpoints, checkpoints, 'bib')




	const columns = [
    {
      title: 'เลขบิบ',
      dataIndex: 'bib',
      key: 'bib'
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'runner',
      key: 'name',
      render: (item, record) => {
        return <Link to={`/participant/${organizId}/${eventId}/${record.bib}`}><span>{item?.name}</span></Link>
      }
    },
    {
      title: 'race',
      dataIndex: 'runner',
      key: 'race',
      render: (item) => {
        return <span>{item?.race}</span>
      }
    },
    {
      title: 'time',
      dataIndex: 'time',
      key: 'time',
			render: (time) => {
				return moment(time).format('DD/MM/YYYY HH:mm:ss')
			}
    }
  ]



	return <div style={{display: 'flex', flexDirection: 'column'}}>
		<Typography.Title className="fontSukhumvit">Checkpoint: {_get(cpDetail, 'name', '')}</Typography.Title>
    <Card style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px', borderRadius: '8px' }} >
      <Table 
        dataSource={unionPosition}
        columns={columns}
      />
    </Card>
	</div>
}

export default PositionPage