import _get from 'lodash/get'
import { useLocation, useHistory } from 'react-router-dom'

const FetchPage = () => {
  const history = useHistory()
  const location = useLocation()
  const link = _get(location, 'search').substr(6)
  if (link) {
    history.replace(link)
  } else {
    history.replace('/')
  }

  return `Fetching ${link} ...`
}

export default FetchPage