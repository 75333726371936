import React, { useEffect } from 'react'
import { Modal, Form, Input } from 'antd'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'

const GET_ORGANIZ_BY_ID = gql`
  query getOrganiz($_id: MongoID!){
    organizById(_id: $_id) {
      _id
      name
    }
  }
`

const UPDATE_ORGANIZ_BY_ID = gql`
  mutation updateOrganizById($record: UpdateByIdorganizInput!) {
    organizUpdateById(record: $record) {
      recordId
    }
  }
`

function EditOrganiz({ editId, refetch }) {
  const { data, loading } = useQuery(GET_ORGANIZ_BY_ID, { variables: { _id: editId }})
  const [updateOrganiz] = useMutation(UPDATE_ORGANIZ_BY_ID)
  const [form] = Form.useForm()
  const history = useHistory()
  
  useEffect(() => {
    if (!loading) {
      const { organizById: organiz } = data
      const prepareData = {
        name: organiz.name
      }

      form.setFieldsValue(prepareData)
    }
  }, [data, form, loading])

  return (
    <Modal 
      title="แก้ไขผู้จัดงาน" 
      visible
      onOk={() => form.submit()}
      onCancel={() => history.push('/organizer')}
    >
      <Form.Provider
        onFormFinish={async (formName, { values }) => {
          if (formName === 'addOrganizer') {
            const { name } = values
            const record = {
              _id: editId,
              name
            }
            try {
              await updateOrganiz({ variables: { record }})
              Modal.success({
                content: 'แก้ไขผู้จัดสำเร็จ',
                onOk: () => {
                  history.push('/organizer')
                  refetch()
                }
              })
            } catch (e) {
              console.log('e', e)
            }
          }
        }}
      >
        <Form form={form} name="addOrganizer">
          <Form.Item
            label="ชื่อผู้จัดงาน"
            name="name"
            rules={
              [{
                required: true, message: 'กรุณากรอกข้อมูล'
              }]
            }
          >
            <Input />
          </Form.Item>
        </Form>
      </Form.Provider>
    </Modal>
  )
}

export default EditOrganiz