import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { Layout, Button, Typography, Table, Space, Card } from "antd";

const GET_EVENTS = gql`
  query getEventByOrganizer($organizId: MongoID) {
    eventMany(filter: { organizId: $organizId, deleted: false }) {
      _id
      name
      slug
    }
  }
`;

function ListClaimReward() {
  const history = useHistory();
  const { organizId } = useParams();
  const { data: event, loading } = useQuery(GET_EVENTS, {
    variables: { organizId },
    fetchPolicy: "cache-and-network",
  });
  console.log(event);
  const columns = [
    {
      title: "ชื่องาน",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "จัดการ",
      dataIndex: "_id",
      key: "_id",
      render: (_id) => {
        return (
          <Space>
            <Button
              onClick={() => history.push(`/claimReward/${organizId}/${_id}`)}
            >
              Claim
            </Button>
          </Space>
        );
      },
    },
  ];

  if (loading) return <div>loading events...</div>;

  const { eventMany: events } = event;

  return (
    <Layout>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title className="fontSukhumvit">
          เลือกรับรางวัล
        </Typography.Title>
      </div>
      <Card
        style={{
          border: "1px solid rgb(235, 235, 235)",
          boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px",
          borderRadius: "8px",
        }}
      >
        <Table
          rowKey={(record) => record._id}
          columns={columns}
          dataSource={events}
          pagination={{ pageSize: 100 }}
        />
      </Card>
    </Layout>
  );
}

export default ListClaimReward;
