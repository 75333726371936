import { HttpLink } from 'apollo-link-http';
import { ApolloClient, InMemoryCache  } from '@apollo/client'

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_RACEPACK_URI
  }),
  cache: new InMemoryCache(),
})


export default client