import React from 'react'
import { Modal, Form, Input } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, gql } from '@apollo/client'

const MUTATION_CHANGE_PASSWORD = gql`
  mutation changePassword($_id: MongoID!, $password: String!) {
    changePassword(_id: $_id, password: $password) {
      _id
      email
      userId
    }
  }
`

function ChangePassword({ editId }) {
  const [changePassword] = useMutation(MUTATION_CHANGE_PASSWORD)
  const [form] = Form.useForm()
  const history = useHistory()
  const { organizId } = useParams()

  return (
    <Modal
      title="แก้ไขรหัสผ่าน"
      visible
      onOk={() => form.submit()}
      onCancel={() => history.push(`/users/${organizId}`)}
    >
      <Form.Provider
        onFormFinish={async (formName, { values }) => {
          if (formName === 'changePassword') {
            const { password } = values
            try {
              const { data: { changePassword: { email }} } = await changePassword({
                variables: {
                  _id: editId,
                  password
                }
              })
              Modal.success({
                title: `แก้ไขรหัสผ่านของ ${email} สำเร็จ`,
                content: `รหัสผ่านใหม่คือ ${password}`,
                onOk: () => {
                  history.push(`/users/${organizId}`)
                }
              })
            } catch (e) {
              console.error(e);
            }
          }
        }}
      >
        <Form form={form} name="changePassword">
          <Form.Item
            label="รหัสผ่านใหม่"
            name="password"
            rules={
              [{
                required: true, message: 'กรุณากรอกข้อมูล'
              }]
            }
          >
            <Input placeholder='กรอกรหัสผ่าน'/>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Modal>
  )
}

export default ChangePassword