import React, { useEffect } from 'react'
import { 
  Space,
  Layout,
  Form,
  Input,
  Button,
  DatePicker,
  Modal,
  Breadcrumb,
  Switch,
  InputNumber,
  message
} from 'antd'
import { gql, useMutation } from '@apollo/client'
import { useParams, useHistory, Link } from 'react-router-dom'
import client from './../index'
import moment from 'moment'

const CREATE_EVENT = gql`
  mutation createEvent($record: CreateOneeventInput!) {
    eventCreateOne(record: $record) {
      record {
        name
      }
    }
  }
`
const GET_EVENT = gql`
  query getEvent($_id: MongoID!) {
    eventById(_id: $_id) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        position
        cutOffTime
        name
        distance
      }
    }
  }
`

const UPDATE_EVENT = gql`
  mutation updateEvent($record: UpdateByIdeventInput!) {
    eventUpdateById(record: $record) {
      recordId
    }
  }
`

function FormEvent() {
  const [createEvent] = useMutation(CREATE_EVENT)
  const [updateEvent] = useMutation(UPDATE_EVENT)
  const { eventId, organizId } = useParams()
  const [form] = Form.useForm()
  const isEdit = !!eventId
  const history = useHistory()

  useEffect(() => {
    async function init() {
      if (isEdit) {
        const { data: { eventById: eventData }} = await client.query({ query: GET_EVENT, variables: { _id: eventId}, fetchPolicy: 'network-only'})
        const prepareData = {
          name: eventData.name,
          slug: eventData.slug,
          dateTime: [moment(eventData.startTime), moment(eventData.endTime)],
          haveChipTime: eventData.haveChipTime,
          checkpoints: eventData.checkpoints.map(({ cutOffTime, name, distance }) => ({ 
            cutOffTime: moment(cutOffTime),
            name,
            distance
          })),
        }

        form.setFieldsValue(prepareData)
      }
    }

    init()
  }, [eventId, form, isEdit])
  
  const handleCreateEvent = async (value) => {
    if (!value.checkpoints) {
      message.error('กรุณาเพิ่มจุดเช็คพอยท์')
      return
    }

    const record = {
      name: value.name,
      slug: value.slug,
      organizId: organizId,
      startTime: value.dateTime[0],
      endTime: value.dateTime[1],
      haveChipTime: value.haveChipTime || false,
      checkpoints: value.checkpoints && value.checkpoints.map((cp, index) => ({
        position: index+1,
        cutOffTime: cp.cutOffTime,
        name: cp.name,
        distance: cp.distance
      }))
    }
    if (!isEdit) {
      try {
        await createEvent({variables: { record }})
        Modal.success({ content: 'สร้างกิจกรรมสำเร็จ', onOk: () => history.replace(`/fetchPage?link=/event/${organizId}`)})
      } catch (e) {
        throw e
      }
      return
    }

    record._id = eventId
    try {
      await updateEvent({variables: { record }})
      Modal.success({ content: 'แก้ไขกิจกรรมสำเร็จ', onOk: () => history.replace(`/fetchPage?link=/event/${organizId}`)})
    } catch (e) {
      throw e
    }
  }
  
  return (
    <Layout>
      <Space style={{ width: '100%' }} direction="vertical">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/event/${organizId}`}>การแข่งขันทั้งหมด</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? 'แก้ไขการแข่งขัน' : 'เพิ่มการแข่งขัน'}
          </Breadcrumb.Item>
        </Breadcrumb>
        {/* <Typography.Title>{isEdit ? 'แก้ไขการแข่งขัน' : 'เพิ่มการแข่งขัน'}</Typography.Title> */}
        <Layout.Content>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            onFinish={handleCreateEvent}
            form={form}
          >
            <Form.Item
              label="ชื่อการแข่งขัน"
              name="name"
              rules={
                [{
                  required: true, message: 'กรุณากรอกข้อมูล'
                }]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={
                [{
                  required: true, message: 'กรุณากรอกข้อมูล'
                }]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="เวลาเริ่ม-สิ้นสุด"
              name="dateTime"
              rules={
                [{
                  required: true, message: 'กรุณากรอกข้อมูล'
                }]
              }
            >
              <DatePicker.RangePicker placeholder={['เวลาเริ่มต้นการแข่งขัน', 'เวลาจบการแข่งขัน']} showTime />
            </Form.Item>
            <Form.Item
              label='คำนวณชิพไทม์'
              name="haveChipTime"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.List name="checkpoints">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field) => (
                      <div key={field.key}>
                          <hr style={{borderTop: '1px dashed #000'}}/>
                          <Form.Item
                            {...field}
                            label={`เช็คพอยท์ ${field.fieldKey + 1}`}
                            name={[field.name, 'cutOffTime']}
                            fieldKey={[field.fieldKey, 'cutOffTime']}
                            rules={
                              [{
                                required: true, message: 'กรุณากรอกข้อมูล'
                              }]
                            }
                          >
                            <DatePicker placeholder="เวลาคัทอ๊อฟ" showTime />
                          </Form.Item>
                        <Form.Item
                          {...field}
                          label={`ชื่อเช็คพอยท์ที่ ${field.fieldKey + 1}`}
                          name={[field.name, 'name']}
                          fieldKey={[field.fieldKey, 'name']}
                          rules={
                            [{
                              required: true, message: 'กรุณากรอกข้อมูล'
                            }]
                          }
                        >
                          <Input placeholder="ชื่อเช็คพอยท์" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label='ระยะทาง (km.)'
                          name={[field.name, 'distance']}
                          fieldKey={[field.fieldKey, 'distance']}
                          rules={
                            [{
                              required: true, message: 'กรุณากรอกข้อมูล'
                            }]
                          }
                        >
                          <InputNumber placeholder="ระยะ (km.)" min={0} />
                        </Form.Item>
                      </div>
                    ))}
                    <Form.Item
                      wrapperCol={{ span: 14, offset: 4 }}
                    >
                      <Space>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                        >
                          เพิ่มเช็คพอยท์
                        </Button>
                        {fields.length !== 0
                        ? <Button
                          type="danger"
                          onClick={() => remove(fields.length-1)}
                        >
                          ลบเช็คพอยท์
                        </Button>
                        : null}
                      </Space>
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
            <Form.Item
              wrapperCol={{ span: 14, offset: 4 }}
            >
              <Space>
                <Button style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px'}} type="primary" htmlType="submit">{isEdit ? 'บันทึก' : 'สร้างกิจกรรม'}</Button>
                {isEdit && <Button onClick={() => history.push(`/event/${organizId}`)}>ยกเลิก</Button>}
              </Space>
            </Form.Item>
          </Form>
        </Layout.Content>
      </Space>
    </Layout>
  )
}

export default FormEvent