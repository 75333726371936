import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from "firebase/app"
import "firebase/auth"
import * as serviceWorker from './serviceWorker';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { ProSidebarProvider } from 'react-pro-sidebar'

import Router from './Router'
import firebaseConfig from './firebaseConfig'
import 'antd/dist/antd.css';
import './index.css';
firebase.initializeApp(firebaseConfig)

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    {/* <React.StrictMode> */}
    <ProSidebarProvider>
      <Router />
    </ProSidebarProvider>
    {/* </React.StrictMode> */}
  </ApolloProvider>,
  document.getElementById('root')
);

export default client
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
