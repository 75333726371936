const firebaseConfig = {
  apiKey: "AIzaSyB26eaDiJ-X2vZ0AA2pU0v9IVGxaRBTeBc",
  authDomain: "thairun-checkpoints-admin.firebaseapp.com",
  databaseURL: "https://thairun-checkpoints-admin.firebaseio.com",
  projectId: "thairun-checkpoints-admin",
  storageBucket: "thairun-checkpoints-admin.appspot.com",
  messagingSenderId: "343178690401",
  appId: "1:343178690401:web:34c47364ad58bed1074f20"
}

export default firebaseConfig
