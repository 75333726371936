import React, { useReducer, useEffect, useMemo, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
// import { Menu } from 'antd'
// import { useQuery, gql } from '@apollo/client'
import { Layout } from "antd";
import * as firebase from "firebase";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRunning,
  faUser,
  faCog,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "./context";
import Login from "./Auth/Login";
import AddUser from "./User/CreateUser";
import ListUsers from "./User/ListUsers";
import ListOrganiz from "./Organiz/ListOrganiz";
import FormEvent from "./Event/FormEvent";
import EventByOrganiz from "./Event/EventByOrganiz";
import ListCheckpoints from "./Checkpoint/ListCheckpointsNew";
import PublicBoard from "./Checkpoint/PublicBoardNew";
import AddRunner from "./Runner/AddRunner";
import ListRunner from "./Runner/ListRunner";
import Dashboard from "./Checkpoint/dashboard";
import FetchPage from "./components/FetchPage";
import PositionPage from "./Checkpoint/Position";
import Result from "./Results/Result";
import ResultSuan from "./Results/ResultSuan";
import ResultALC from "./Results/resultAlC"
import ResultALCB from './Results/resultAIC2'
import ResultBuff from './Results/ResultBuff'
import ResultTeam from "./Results/ResultTeam";
import Participant from "./Results/Participant";
import _get from "lodash/get";
import LastCheckpoint from "./Checkpoint/LastCheckpoint";
import ListClaimReward from "./ClaimReward/ListClaimReward";
import ClaimReward from "./ClaimReward/ClaimReward";
import Reward from "./ClaimReward/Reward";
import Display from "./Results/Display";
import RunerDetail from './Results/runnerDetail'

function PrivateRoute({ children, ...rest }) {
  const { isLoading, userId } = useContext(AuthContext);
  if (isLoading) return <div>checking auth.</div>;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return userId ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        );
      }}
    />
  );
}

// function Header() {
//   const { signOut } = useContext(AuthContext)
//   // if (isLoading) return <div>checking auth.</div>

//   return (
//     <Layout.Header>
//       <div className="logo">
//         <img src={process.env.PUBLIC_URL + '/logo.png'}alt="logo" />
//       </div>
//       <Button onClick={ ()=> signOut() }  key="1" className="signout">ออกจากระบบ</Button>
//     </Layout.Header>
//   )
// }

// const GET_ORGANIZ_INFO = gql`
//   query getInfoOrganiz($_id: MongoID!) {
//     organizById(_id: $_id) {
//       name
//       _id
//     }
//   }
// `

function WrapComponent({ children }) {
  const { organizId } = useParams();
  const { toggleSidebar } = useProSidebar();
  let location = useLocation();

  const pathname = location.pathname.split("/");

  const activedPathname = _get(pathname, [1]);

  // const { data, loading } = useQuery(GET_ORGANIZ_INFO, { variables: { _id: organizId }})
  const { signOut } = useContext(AuthContext);
  return (
    <div
      style={({ height: "100vh" }, { display: "flex", flexDirection: "row" })}
    >
      <div className="desktop">
        {activedPathname !== 'display' && <Sidebar
          breakPoint="sm"
          defaultCollapsed={true}
          transitionDuration={800}
          rootStyles={{
            background:
              "linear-gradient(180deg, rgba(189, 24, 139) 0%, rgba(125, 28, 125) 35%, rgba(65, 26, 111) 100%)",
          }}
          rtl={false}
          style={{ height: "100vh" }}
          className="rm_bg"
          // width={'100px'}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Menu>
              <MenuItem
                // onClick={() => {
                //   collapseSidebar();
                // }}
                style={{ textAlign: "center", padding: "0px" }}
              >
                {" "}
                <div>
                  <Link to="/">
                    <img
                      style={{ width: "50px" }}
                      src="/logo-thairun.png"
                      alt="ThaiRun"
                    />
                  </Link>
                </div>
              </MenuItem>
              <Link to={`/event/${organizId}`}>
                <MenuItem
                  st
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "event" ? "F9A01B" : "white",
                        }}
                        icon={faRunning}
                      />
                      <>
                        <span className="menu-title">Races</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>

              <Link to={`/users/${organizId}`}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "users" ? "F9A01B" : "white",
                        }}
                        icon={faUser}
                      />
                      <>
                        <span className="menu-title">User</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>

              <Link to={`/listClaimReward/${organizId}`}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "claim" ? "F9A01B" : "white",
                        }}
                        icon={faUser}
                      />
                      <>
                        <span className="menu-title">Claim</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
            </Menu>
            <Menu>
              <Link to="/organizer">
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "organizer"
                              ? "F9A01B"
                              : "white",
                        }}
                        icon={faCog}
                      />
                      <>
                        <span className="menu-title">Settings</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
              <Link onClick={() => signOut()}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{ width: "24", height: "24", color: "white" }}
                        icon={faSignOut}
                      />
                      <>
                        <span className="menu-title">Logout</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
            </Menu>
          </div>
        </Sidebar>}
      </div>
      <div className="mobile">
        <Sidebar
          breakPoint="sm"
          defaultCollapsed={true}
          transitionDuration={800}
          rootStyles={{
            background:
              "linear-gradient(180deg, rgba(189, 24, 139) 0%, rgba(125, 28, 125) 35%, rgba(65, 26, 111) 100%)",
          }}
          rtl={false}
          style={{ height: "100vh" }}
          className="rm_bg"
          // width={'100px'}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Menu>
              <MenuItem
                // onClick={() => {
                //   collapseSidebar();
                // }}
                style={{ textAlign: "center", padding: "0px" }}
              >
                {" "}
                <div>
                  <Link to="/">
                    <img
                      style={{ width: "50px" }}
                      src="/logo-thairun.png"
                      alt="ThaiRun"
                    />
                  </Link>
                </div>
              </MenuItem>
              <Link to={`/event/${organizId}`}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "event" ? "F9A01B" : "white",
                        }}
                        icon={faRunning}
                      />
                      <>
                        <span className="menu-title">Races</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
              <Link to={`/users/${organizId}`}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "users" ? "F9A01B" : "white",
                        }}
                        icon={faUser}
                      />
                      <>
                        <span className="menu-title">User</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>

              <Link to={`/listClaimReward/${organizId}`}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "claim" ? "F9A01B" : "white",
                        }}
                        icon={faUser}
                      />
                      <>
                        <span className="menu-title">Claim</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
            </Menu>
            <Menu>
              <Link to="/organizer">
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{
                          width: "24",
                          height: "24",
                          color:
                            activedPathname === "organizer"
                              ? "F9A01B"
                              : "white",
                        }}
                        icon={faCog}
                      />
                      <>
                        <span className="menu-title">Settings</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
              <Link onClick={() => signOut()}>
                <MenuItem
                  icon={
                    <>
                      <FontAwesomeIcon
                        style={{ width: "24", height: "24", color: "white" }}
                        icon={faSignOut}
                      />
                      <>
                        <span className="menu-title">Logout</span>
                      </>
                    </>
                  }
                ></MenuItem>
              </Link>
            </Menu>
          </div>
        </Sidebar>
        <span className="menuTriggerIcon" onClick={() => toggleSidebar()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
              fill="#F9A01B"
            />
          </svg>
        </span>
      </div>
      <Layout >{children}</Layout>
    </div>
    // <Layout>
    //   <Row style={{ width: '100%', margin: 'auto'}}>
    //     <Col span={24}>
    //       <PageHeader
    //         title={`หน้าจัดการของผู้จัด ${!loading ? data.organizById.name : ''}`}
    //       />
    //     </Col>
    //     <Col span={4}>
    //       <Menu
    //         mode="inline"
    //         style={{ minHeight: 500 }}
    //       >
    //         <Menu.ItemGroup key="g1" title="การแข่งขัน">
    //           <Menu.Item key="1">
    //             <Link to={`/event/${organizId}`}>
    //               การแข่งขันทั้งหมด
    //             </Link>
    //           </Menu.Item>
    //           <Menu.Item key="2">
    //             <Link to={`/manage-event/add/${organizId}`}>
    //               สร้างการแข่งขัน
    //             </Link>
    //           </Menu.Item>
    //         </Menu.ItemGroup>
    //         <Menu.ItemGroup key="g2" title="ผู้ใช้">
    //           <Menu.Item key="3">
    //             <Link to={`/users/${organizId}`}>
    //               จัดการผู้ใช้
    //             </Link>
    //           </Menu.Item>
    //           <Menu.Item key="4">
    //             <Link to={`/add-user/${organizId}`}>
    //               เพิ่มผู้ใช้
    //             </Link>
    //           </Menu.Item>
    //         </Menu.ItemGroup>
    //         <Menu.ItemGroup key="g3" title="อื่นๆ">
    //           <Menu.Item key="5">
    //             <Link to="/organizer">
    //               ออกจากหน้าจัดการ
    //             </Link>
    //           </Menu.Item>
    //         </Menu.ItemGroup>
    //       </Menu>
    //     </Col>
    //     <Col span={20} style={{ padding: 40 }}>
    //       {children}
    //     </Col>
    //   </Row>
    // </Layout>
  );
}

function RouterConfig() {
  // const { signOut } = useContext(AuthContext)
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SELECT_ORGANIZER":
          return {
            ...prevState,
            organizInfo: action.organizInfo,
          };
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userId: action.userId,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userId: action.userId,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isLoading: false,
            isSignout: true,
            userId: null,
            organizInfo: {},
          };
        default:
          return;
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userId: null,
      organizInfo: {},
    }
  );

  useEffect(() => {
    document.title = "Admin Checkpointer | Thairun";
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        dispatch({ type: "RESTORE_TOKEN", userId: user.uid });
      } else {
        dispatch({ type: "SIGN_OUT" });
      }
    });
  }, []);

  const authContext = useMemo(
    () => ({
      isLoading: state.isLoading,
      userId: state.userId,
      organizInfo: state.organizInfo,
      selectOrganizer: ({ organizInfo }) => {
        dispatch({ type: "SELECT_ORGANIZER", organizInfo });
      },
      signIn: async (data) => {
        return firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            return firebase
              .auth()
              .signInWithEmailAndPassword(data.email, data.password)
              .then(({ user }) => {
                dispatch({ type: "SIGN_IN", userId: user.uid });
              });
          })
          .catch((error) => {
            throw error;
          });
      },
      signOut: async () => {
        await firebase.auth().signOut();
        dispatch({ type: "SIGN_OUT" });
        window.location.replace("/");
      },
    }),
    [state.isLoading, state.organizInfo, state.userId]
  );
  // const { signOut } = useContext(AuthContext)

  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (state.isLoading) {
                return <div>Checking Auth...</div>;
              }
              return state.userId ? (
                <Redirect to={{ pathname: "/organizer" }} />
              ) : (
                <Login />
              );
            }}
          />
          <Route path="/board/:eventId" component={PublicBoard} />
          <Route path="/participant/:organizId/:eventId/:bib">
            <WrapComponent>
              <Participant />
            </WrapComponent>
          </Route>
          <PrivateRoute>
            {/* <Header /> */}
            <Route path="/organizer">
              <ListOrganiz />
            </Route>
            <Route path="/listClaimReward/:organizId/">
              <WrapComponent>
                <ListClaimReward />
              </WrapComponent>
            </Route>
            <Route path="/claimReward/:organizId/:eventId">
              <WrapComponent>
                <ClaimReward />
              </WrapComponent>
            </Route>
            <Route path="/reward/:organizId/:eventId">
              <WrapComponent>
                <Reward />
              </WrapComponent>
            </Route>
            <Route path="/checkpoints/:organizId/:eventId">
              <WrapComponent>
                <ListCheckpoints />
              </WrapComponent>
            </Route>
            <Route path="/team/:organizId/:eventId">
              <WrapComponent>
                <ResultTeam />
              </WrapComponent>
            </Route>
            <Route path="/results/:organizId/:eventId">
              <WrapComponent>
                <Result />
              </WrapComponent>
            </Route>
            <Route path="/suan/:organizId/:eventId">
              <WrapComponent>
                <ResultSuan />
              </WrapComponent>
            </Route>
            <Route path="/alc/:organizId/:eventId">
              <WrapComponent>
                <ResultALC />
              </WrapComponent>
            </Route>
            <Route path="/alc2/:organizId/:eventId">
              <WrapComponent>
                <ResultALCB />
              </WrapComponent>
            </Route>
            <Route path="/buff/:organizId/:eventId">
              <WrapComponent>
                <ResultBuff/>
              </WrapComponent>
            </Route>
            <Route path="/running/:organizId/:eventId/">
              <WrapComponent>
                <LastCheckpoint />
              </WrapComponent>
            </Route>
            <Route path="/dashboard/:organizId/:eventId">
              <WrapComponent>
                <Dashboard />
              </WrapComponent>
            </Route>
            <Route path="/position/:organizId/:eventId/:position">
              <WrapComponent>
                <PositionPage />
              </WrapComponent>
            </Route>
            <Route path="/event/:organizId">
              <WrapComponent>
                <EventByOrganiz />
              </WrapComponent>
            </Route>
            <Route path="/users/:organizId">
              <WrapComponent>
                <ListUsers />
              </WrapComponent>
            </Route>
            <Route path="/add-user/:organizId">
              <WrapComponent>
                <AddUser />
              </WrapComponent>
            </Route>
            <Route path="/manage-event/add/:organizId">
              <WrapComponent>
                <FormEvent />
              </WrapComponent>
            </Route>
            <Route exact path="/runner/:organizId/:eventId">
              <WrapComponent>
                <ListRunner />
              </WrapComponent>
            </Route>
            <Route path="/runner/:organizId/:eventId/:bib">
              <WrapComponent>
                <RunerDetail />
              </WrapComponent>
            </Route>
            <Route path="/runner-import/:organizId/:eventId">
              <WrapComponent>
                <AddRunner />
              </WrapComponent>
            </Route>
            <Route path="/manage-event/edit/:organizId/:eventId">
              <WrapComponent>
                <FormEvent />
              </WrapComponent>
            </Route>
            <Route path="/display">
              <WrapComponent>
                <Display />
              </WrapComponent>
            </Route>
            <Route path="/fetchPage" component={FetchPage} />
          </PrivateRoute>
        </Switch>
        {/* <Layout.Footer style={{ textAlign: "center" }}>
          Powered by Thai.run
        </Layout.Footer> */}
      </Router>
    </AuthContext.Provider>
  );
}

export default RouterConfig;
