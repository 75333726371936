import React, { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Modal, notification, message } from "antd";
const GET_EVENT_ONE = gql`
  query getEventOne($_id: MongoID!) {
    eventById(_id: $_id) {
      _id
      slug
      reward {
        _id
      }
    }
  }
`;
const GET_REWARD_RUNNER = gql`
  query getRewardRunner($slug: String!, $RFID: String!) {
    checkpointToReward(slug: $slug, RFID: $RFID)
  }
`;
const GET_RUNNER = gql`
  query getRunner($slug: String!, $RFID: String!) {
    runnerOne(filter: { slug: $slug, RFID: $RFID }) {
      _id
      name
      bib
      slug
      RFID
    }
  }
`;

const SEND_REWARD = gql`
  mutation sendReward($rewardId: MongoID!, $runnerId: MongoID!) {
    claimCreateOne(record: { rewardId: $rewardId, runnerId: $runnerId }) {
      record {
        _id
      }
    }
  }
`;
export default function Reward() {
  const [rfid, setRfid] = useState();
  const tmp = useRef("");
  const { eventId } = useParams();
  // console.log(eventId, "eeventID");
  const [sendRewardData] = useMutation(SEND_REWARD);
  const { data: dataShowSlug, loading: loadingShowSlug } = useQuery(
    GET_EVENT_ONE,
    {
      variables: { _id: eventId },
    }
  );
  const [commit] = useLazyQuery(GET_REWARD_RUNNER, {
    skip: true,
  });
  const [user] = useLazyQuery(GET_RUNNER, {
    skip: true,
  });

  // const { refetch } = useQuery(GET_REWARD_RUNNER, {
  //   variables: { slug: "", RFID: "" },
  //   skip: true,
  // });
  useEffect(() => {
    window.addEventListener("keypress", keyListener);
    return () => window.removeEventListener("keypress", keyListener);
  }, [keyListener]);

  if (loadingShowSlug) return <div>loading runner...</div>;

  console.log(dataShowSlug.eventById.reward._id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function keyListener(e) {
    if (rfid) {
      return;
    }
    if (e.key === "Enter") {
      setRfid(tmp.current);
      await reward(tmp.current);
      tmp.current = "";
    } else {
      tmp.current += e.key;
    }
    console.log(e, rfid, tmp.current);
  }
  async function reward(RFID) {
    const { data, loading } = await user({
      variables: { slug: dataShowSlug.eventById.slug, RFID: RFID },
    });
    if (loading) return <div> loading..... </div>;
    commit({
      variables: { slug: dataShowSlug.eventById.slug, RFID: RFID },
    }).then((res) => {
      if (res.data.checkpointToReward === "สามารถรับรางวัลได้") {
        Modal.confirm({
          title: res.data.checkpointToReward,
          content: (
            <div>
              <h1>
                Nama <span> {data.runnerOne.name} </span>
              </h1>
              <h5>
                bib <span> {data.runnerOne.bib} </span>
              </h5>
              <h5>
                slug <span> {data.runnerOne.slug} </span>
              </h5>
              <h5>
                RFID <span> {data.runnerOne.RFID} </span>
              </h5>
            </div>
          ),
          onOk() {
            sendReward(data.runnerOne._id);
          },
          // onOk: sendReward(data.runnerOne._id),
          // open: sendReward,
          onCancel: "",
          okText: "Yes",
          okType: "",
          cancelText: "No",
          cancelType: "danger",
        });
      } else {
        notification.error({
          message: res.data.checkpointToReward,
          placement: "top",
        });
      }
    });
    setRfid();
  }
  async function sendReward(userId) {
    // console.log(userId, "8888888");
    await sendRewardData({
      variables: {
        rewardId: dataShowSlug.eventById.reward._id,
        runnerId: userId,
      },
    });
    message.open({
      type: "success",
      content: "รับรางวัลสำเร็จ",
    });
    // console.log(dataShowSlug.eventById.reward._id);
    // console.log("first");
  }
  return <div>Reward</div>;
}