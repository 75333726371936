import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Input, Button } from 'antd'
import BG from './bg.png'

const GET_TIME = gql`
  query getTime($slug: String!, $bib: String!) {
    getTime(slug: $slug, bib: $bib){
      name
      bib
      time
    }
  }
`


let rfid = ''
let textRFID = ''

const Display = () => {
  const [textQr, setTextQr] = useState('')
  const [textSearch, setTextSearch] = useState('')
  const { data, refetch } = useQuery(GET_TIME, { variables: { slug: 'sp100ep2', bib: '561' } })

  useEffect(() => {
    window.addEventListener("keypress", keyListener);
    // console.log("first listener");
    return () => {
      // console.log("xxx");
      window.removeEventListener("keypress", keyListener);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function keyListener(e) {
    if (e.key === "Enter") {
      textRFID = rfid;
      rfid = "";
      console.log(textRFID, 'textRFID');
      setTextQr(textRFID)

    } else {
      rfid += e.key;
    }
  }

  useEffect(() => {
    console.log('fetch:', textQr)
    const getTime = async () => {
      const arrData = textQr.split('/')
      const bib = arrData.pop()
      const slug = arrData.pop()
      refetch({ slug: slug, bib: bib })
    }
    getTime()

  }, [textQr, refetch])

  const onSearch = () => {
    console.log(textSearch)
     refetch({ slug: 'sp100ep2', bib: textSearch })
  }

  return (
    <>
      <div style={{ width: '100%', position: 'relative', textAlign: 'center', color: '#000', fontFamily: 'Sukhumvit Set', fontWeight: 'bold', marginTop: '60px' }}>
        <img src={BG} style={{ width: "100%" }} alt='img' />
        <div style={{ position: 'absolute', left: '20%', top: '10%', fontSize: '4vw' }}>Bib {data?.getTime?.bib}</div>
        <div style={{ position: 'absolute', left: '20%', top: '45%', fontSize: '2.5vw' }}>{data?.getTime?.name}</div>
        <div style={{ position: 'absolute', left: '65%', top: '10%', fontSize: '3vw' }}>เวลาทั้งหมด</div>
        <div style={{ position: 'absolute', left: '66%', top: '40%', fontSize: '3vw' }}>{data?.getTime.time || '00:00:00'}</div>
      </div>
      <div style={{bottom: 50, position: 'absolute', left: '40%', display: 'flex', gap: 10}}>
        <Input onChange={(e)=> setTextSearch(e.target.value)} placeholder='bib' type='number' />
        <Button onClick={()=> onSearch()}>Submit</Button>
      </div>
    </>
  )
}


export default Display