import React from 'react'
import { Table, Button, Typography, Space, Modal, message, Layout,Card } from 'antd'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useMutation ,useQuery, gql } from '@apollo/client'
import ChangePassword from './ChangePassword'

const GET_USER_BY_ORGANIZ = gql`
  query getUserByOrganiz($organizId: MongoID){
    userMany(filter: {
      organizId: $organizId
    }) {
      _id
      userId
      email
    }
  }
`

const MUTATION_DELETE_USER = gql`
  mutation deleteUser($_id: MongoID!) {
    deleteUser(_id: $_id) 
  }
`

function useRouteQuery() {
  return new URLSearchParams(useLocation().search)
}

function ListUsers() {
  const { organizId } = useParams()
  const { data, loading, refetch } = useQuery(GET_USER_BY_ORGANIZ, { variables: { organizId }, fetchPolicy: 'cache-and-network' })
  const [deleteUser] = useMutation(MUTATION_DELETE_USER)
  const query = useRouteQuery()
  const history = useHistory()

  const editId = query.get('editId')
  const isEdit = editId !== null
  
  const handleRemoveUser = async (_id) => {
    Modal.confirm({
      title: 'คุณต้องการลบผู้ใช้นี้หรือไม่ ?',
      async onOk() {
        const { data: { deleteUser: result } } = await deleteUser({variables: { _id }})
        if (result) {
          message.success('ลบผู้ใช้สำเร็จ')
          refetch()
        }
      },
      onCancel() {
        return
      }
    })
  }
  
  const columns = [
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email'
    }, {
      title: 'จัดการ',
      dataIndex: '_id',
      render: _id => {
        return (
          <Space>
            <Button onClick={() => history.push(`?editId=${_id}`)}>เปลี่ยนรหัสผ่าน</Button>
            <Button type="danger" onClick={() => handleRemoveUser(_id)}>ลบ</Button>
          </Space>
        )
      }
    }
  ]
  
  if (loading) return <div>loading users...</div>
  const { userMany: users } = data

  return (
    <Layout>
      <div>
        <Typography.Title className='fontSukhumvit' >จัดการผู้ใช้</Typography.Title>
        <div style={{paddingBottom : '10px'}}><Button style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px'}} onClick={() => history.push(`/add-user/${organizId}`)}>เพิ่มผู้ใช้</Button></div>
        <Card style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px', borderRadius: '8px' }} >
          <Table rowKey={record => record._id} columns={columns} dataSource={users} />
        </Card>
        {isEdit && <ChangePassword editId={editId} />}
      </div>
    </Layout>
  )
}

export default ListUsers